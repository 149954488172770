var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-collect" },
    [
      _c("HeadNav"),
      _vm.infoStatus
        ? _c("div", { staticClass: "order-address" }, [
            _c("p", { staticClass: "user-info" }, [
              _c("span", { staticClass: "user-name fs-weight" }, [
                _vm._v(_vm._s(_vm.detail.realname)),
              ]),
              _c("span", { staticClass: "user-phone fs-weight" }, [
                _vm._v(_vm._s(_vm._f("hideMobile")(_vm.detail.mobile))),
              ]),
            ]),
            _c("p", { staticClass: "user-address" }, [
              _vm._v(_vm._s(_vm.detail.area_string)),
            ]),
            _c("div", { staticClass: "order-line" }),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "order-content" },
        [
          _c("order-goods", { attrs: { goodsList: _vm.detail.item_list } }),
          _c(
            "div",
            { staticClass: "order-info" },
            [
              _c("div", { staticClass: "info-title fs-weight" }, [
                _vm._v("订单基本信息"),
              ]),
              _c(
                "div",
                { staticClass: "info-item" },
                [
                  _c("p", [_vm._v("商品总价")]),
                  _vm.type == 2
                    ? [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.all_total) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ]
                    : [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v("¥" + _vm._s(_vm.detail.order_amount)),
                        ]),
                      ],
                ],
                2
              ),
              _vm.type == 2 && Number(_vm.detail.reduce_integral)
                ? _c("div", { staticClass: "info-item" }, [
                    _c("p", [_vm._v("满减")]),
                    _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t-" +
                          _vm._s(_vm.detail.reduce_integral) +
                          "积分\n\t\t\t\t\t"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type != 2 && Number(_vm.detail.reduce)
                ? _c("div", { staticClass: "info-item" }, [
                    _c("p", [_vm._v("满减")]),
                    _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v("-¥" + _vm._s(_vm.detail.reduce)),
                    ]),
                  ])
                : _vm._e(),
              _vm.type == 2 && Number(_vm.detail.vouchers_integral)
                ? _c("div", { staticClass: "info-item" }, [
                    _c("p", [_vm._v("优惠券")]),
                    _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t-" +
                          _vm._s(_vm.detail.vouchers_integral) +
                          "积分\n\t\t\t\t\t"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type != 2 && Number(_vm.detail.voucher_amount)
                ? _c("div", { staticClass: "info-item" }, [
                    _c("p", [_vm._v("优惠券")]),
                    _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t-¥" +
                          _vm._s(_vm.detail.voucher_amount) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "info-item" },
                [
                  _c("p", [_vm._v("运费")]),
                  _vm.type == 2
                    ? [
                        _c("p", { staticClass: "fs-weight" }, [
                          _c("span", { staticClass: "fs-weight" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.detail.fee_xj > 0
                                    ? _vm.detail.fee_xj + "元"
                                    : ""
                                ) +
                                "\n              "
                            ),
                          ]),
                          _c("span", { staticClass: "fs-weight" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.detail.fee_xj > 0 &&
                                    _vm.detail.fee_integral > 0
                                    ? "+"
                                    : ""
                                ) +
                                "\n              "
                            ),
                          ]),
                          _c("span", { staticClass: "fs-weight" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.detail.fee_xj == 0 &&
                                    _vm.detail.fee_integral == 0
                                    ? "0积分"
                                    : ""
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.detail.fee_integral > 0
                                  ? _vm.detail.fee_integral + "积分"
                                  : ""
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]
                    : [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v("¥" + _vm._s(_vm.detail.fee)),
                        ]),
                      ],
                ],
                2
              ),
              _vm._l(_vm.explode_fee_text, function (item, index) {
                return _c("p", { key: index, staticClass: "explode-text" }, [
                  _vm._v(_vm._s(item)),
                ])
              }),
              _c("div", { staticClass: "info-all" }, [
                _vm.type === 1 && _vm.payStatus && _vm.fk_show && _vm.zgyh_show
                  ? _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.total_wx > 0
                              ? _vm.total_wallet > 0
                                ? `￥${_vm.detail.total_amount}(微信支付${_vm.total_wx}，余额${_vm.total_wallet})`
                                : `￥${_vm.detail.total_amount}(微信支付${_vm.total_wx})`
                              : "￥" + _vm.detail.total_amount
                          ) +
                          " \n\t\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm.type === 1 &&
                !_vm.fk_show &&
                _vm.detail.pay_way != 20 &&
                _vm.zgyh_show
                  ? _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.total_wx > 0
                              ? _vm.total_wallet > 0
                                ? `￥${_vm.detail.total_amount}(微信支付${_vm.total_wx}，余额${_vm.total_wallet})`
                                : `￥${_vm.detail.total_amount}(微信支付${_vm.total_wx})`
                              : "￥" + _vm.detail.total_amount
                          ) +
                          " \n\t\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm.type === 1 &&
                _vm.detail.pay_way == 20 &&
                !_vm.fk_show &&
                _vm.zgyh_show
                  ? _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.detail.total_amount) +
                          "\n\t\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm.detail.pay_way == 13
                  ? _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.detail.total_amount) +
                          "\n\t\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm.type === 2 && _vm.detail.pay_way != 13
                  ? _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.total_wx > 0
                              ? _vm.total_integral > 0
                                ? _vm.total_wx +
                                  "元+" +
                                  _vm.total_integral +
                                  "积分"
                                : _vm.total_wx + "元"
                              : _vm.total_integral + "积分"
                          ) +
                          "\n\t\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm.type === 3
                  ? _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.detail.total_amount) +
                          "\n\t\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                !_vm.zgyh_show
                  ? _c("p", { staticClass: "fs-weight color-main" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t￥" +
                          _vm._s(_vm.detail.total_amount) +
                          "\n\t\t\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _c("p", [_vm._v("合计：")]),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "order-info" }, [
            _vm.detail.recharge_account
              ? _c("div", { staticClass: "order-line" }, [
                  _c("p", { staticClass: "fs-weight" }, [_vm._v("充值账号")]),
                  _vm.detail.recharge_account
                    ? _c("p", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.detail.recharge_account) +
                            "\n\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", { staticClass: "order-line" }, [
              _c("p", { staticClass: "fs-weight" }, [_vm._v("订单备注")]),
              _vm.detail.remark
                ? _c("p", [_vm._v(_vm._s(_vm.detail.remark))])
                : _c("p", [_vm._v("无")]),
            ]),
            _vm.detail.pay_way && _vm.zgyh_show
              ? _c("div", { staticClass: "order-line" }, [
                  _c("p", { staticClass: "fs-weight" }, [_vm._v("支付方式")]),
                  _vm.detail.pay_way == 1 && !_vm.total_wallet
                    ? _c("p", [_vm._v("\n\t\t\t\t\t\t微信支付\n\t\t\t\t\t")])
                    : _vm._e(),
                  _vm.detail.pay_way == 2
                    ? _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.total_wx > 0 ? `混合支付` : `余额支付`) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm.detail.pay_way == 3
                    ? _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.total_wx > 0 ? `混合支付` : `积分支付`) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm.detail.pay_way == 4
                    ? _c("p", [_vm._v("卡卷兑换")])
                    : _vm._e(),
                  _vm.detail.pay_way == 11
                    ? _c("p", [_vm._v("支付宝支付")])
                    : _vm._e(),
                  _vm.detail.pay_way == 12
                    ? _c("p", [_vm._v("微信支付")])
                    : _vm._e(),
                  _vm.detail.pay_way == 14
                    ? _c("p", [_vm._v("大转盘奖品")])
                    : _vm._e(),
                  _vm.detail.pay_way == 5 ||
                  _vm.detail.pay_way == 7 ||
                  _vm.detail.pay_way == 8 ||
                  _vm.detail.pay_way == 9 ||
                  _vm.detail.pay_way == 10 ||
                  _vm.detail.pay_way == 15 ||
                  _vm.detail.pay_way == 20
                    ? _c("p", [_vm._v("第三方支付")])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c("div", { staticClass: "order-line" }, [
              _c("p", { staticClass: "fs-weight" }, [_vm._v("订单编号")]),
              _c("p", [_vm._v(_vm._s(_vm.detail.pay_order_sn))]),
            ]),
            _vm.detail.create_time
              ? _c("div", { staticClass: "order-line" }, [
                  _c("p", { staticClass: "fs-weight" }, [_vm._v("下单时间")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm._f("formatDateStd")(_vm.detail.create_time))
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm.haveDone === 0
        ? _c(
            "div",
            { staticClass: "order-btn" },
            [
              _c("order-btn", {
                attrs: {
                  orderState: _vm.status,
                  orderData: _vm.detail,
                  ifMore: false,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }