<template>
  <div class="order-collect">
    <HeadNav/>
		
    <div v-if="infoStatus" class="order-address">
      <p class="user-info">
        <span class="user-name fs-weight">{{detail.realname}}</span>
        <span class="user-phone fs-weight">{{detail.mobile | hideMobile}}</span>
      </p>
      <p class="user-address">{{detail.area_string}}</p>
      <div class="order-line" />
    </div>
		
    <!-- 拼团成员信息 -->
    <!-- <div style="background: #fff;">
      <group-member></group-member>
    </div>-->
		<!-- 拼团成员信息 -->
		
    <div class="order-content">
      <order-goods :goodsList="detail.item_list"></order-goods>
      <div class="order-info">
        <div class="info-title fs-weight">订单基本信息</div>
        <div class="info-item">
          <p>商品总价</p>
          <template v-if="type == 2">
            <p class="fs-weight">
							{{ all_total }}
						</p>
          </template>
          <template v-else>
            <p class="fs-weight">¥{{detail.order_amount}}</p>
          </template>
        </div>
				<div
					v-if="type == 2 && Number(detail.reduce_integral)"
					class="info-item"
				>
					<p>满减</p>
					<p class="fs-weight color-main">
						-{{ detail.reduce_integral }}积分
					</p>
				</div>
				<div v-if="type != 2 && Number(detail.reduce)" class="info-item">
					<p>满减</p>
					<p class="fs-weight color-main" >-¥{{ detail.reduce }}</p>
				</div>
				<div
					v-if="type == 2 && Number(detail.vouchers_integral)"
					class="info-item"
				>
					<p>优惠券</p>
					<p class="fs-weight color-main">
						-{{ detail.vouchers_integral }}积分
					</p>
				</div>
				<div
					v-if="type != 2 && Number(detail.voucher_amount)"
					class="info-item"
				>
					<p>优惠券</p>
					<p class="fs-weight color-main">
						-¥{{ detail.voucher_amount }}
					</p>
				</div>
				
        <div class="info-item">
          <p>运费</p>
          <template v-if="type == 2">
			      <p class="fs-weight">
              <span class="fs-weight">
                {{ detail.fee_xj > 0 ? detail.fee_xj + '元' : '' }}
              </span>
							<span class="fs-weight">
								{{ detail.fee_xj > 0  && detail.fee_integral > 0 ? '+' : ''}}
              </span>
							<span class="fs-weight">
                {{ detail.fee_xj == 0  && detail.fee_integral == 0 ? '0积分' : ''}}
              </span>
              {{ detail.fee_integral > 0 ? detail.fee_integral + '积分' : '' }}
            </p>
          </template>
          <template v-else>
            <p class="fs-weight">¥{{detail.fee}}</p>
          </template>
        </div>
	
        <p
					class="explode-text"
					v-for="(item,index) in explode_fee_text"
					:key="index"
				>{{item}}</p>
				
        <div class="info-all">
					<p v-if="type===1 && payStatus && fk_show && zgyh_show" class="fs-weight color-main">
						{{
							total_wx > 0 ? 
							total_wallet > 0 ? `￥${detail.total_amount}(微信支付${total_wx}，余额${total_wallet})` : `￥${detail.total_amount}(微信支付${total_wx})`
							: '￥' + detail.total_amount
						}} 
					</p>
					<p
						v-if="type===1 &&!fk_show && detail.pay_way != 20 && zgyh_show"
						class="fs-weight color-main"
					>
						{{
							total_wx > 0 ? 
							total_wallet > 0 ? `￥${detail.total_amount}(微信支付${total_wx}，余额${total_wallet})` : `￥${detail.total_amount}(微信支付${total_wx})`
							: '￥' + detail.total_amount
						}} 
					</p>
					<p
						v-if="type===1 && detail.pay_way == 20 &&!fk_show && zgyh_show"
						class="fs-weight color-main"
					>
						{{ detail.total_amount }}
					</p>
					<p v-if="detail.pay_way == 13" class="fs-weight color-main">
						{{ detail.total_amount }}
					</p>
					<p v-if="type===2 && detail.pay_way != 13" class="fs-weight color-main">
						{{ 
							total_wx > 0 ? 
							total_integral > 0 ? total_wx + '元+' + total_integral + '积分' : total_wx + '元'
							: total_integral + '积分'
						}}
					</p>
					<p v-if="type === 3" class="fs-weight color-main">
						{{ detail.total_amount }}
					</p>
          <p v-if="!zgyh_show" class="fs-weight color-main">
						￥{{ detail.total_amount }}
					</p>
					<p>合计：</p>
        </div>
      </div>
			
      <div class="order-info">
        <div v-if="detail.recharge_account" class="order-line">
          <p class="fs-weight">充值账号</p>
          <p v-if="detail.recharge_account">
						{{detail.recharge_account}}
					</p>
        </div>
        <div class="order-line">
          <p class="fs-weight">订单备注</p>
          <p v-if="detail.remark">{{detail.remark}}</p>
          <p v-else>无</p>
        </div>
        <div v-if="detail.pay_way && zgyh_show" class="order-line">
          <p class="fs-weight">支付方式</p>
          <p v-if="detail.pay_way == 1 && !total_wallet">
						微信支付
					</p>
          <p v-if="detail.pay_way == 2">
            {{ total_wx > 0 ? `混合支付` : `余额支付` }}
          </p>
          <p v-if="detail.pay_way == 3">
            {{ total_wx > 0 ? `混合支付` : `积分支付` }}
          </p>
          <p v-if="detail.pay_way == 4">卡卷兑换</p>
          
					<!-- 鲸品库支付方式回显 -->
					<p v-if="detail.pay_way == 11">支付宝支付</p>
					<p v-if="detail.pay_way == 12">微信支付</p>
					<p v-if="detail.pay_way == 14">大转盘奖品</p>
					<!-- 鲸品库支付方式回显 -->
					
					<p v-if="
						detail.pay_way == 5 ||
						detail.pay_way == 7 ||
						detail.pay_way == 8 ||
						detail.pay_way == 9 ||
						detail.pay_way == 10 ||
						detail.pay_way == 15 ||
						detail.pay_way == 20"
					>第三方支付</p>
				</div>
        
        <div class="order-line">
          <p class="fs-weight">订单编号</p>
          <p>{{detail.pay_order_sn}}</p>
        </div>
        <div v-if="detail.create_time" class="order-line">
          <p class="fs-weight">下单时间</p>
          <p>{{ detail.create_time | formatDateStd }}</p>
        </div>
      </div>
    </div>
    <div v-if="haveDone===0" class="order-btn">
      <order-btn 
				:orderState="status"
				:orderData="detail"
				:ifMore="false"
			></order-btn>
    </div>
  </div>
</template>

<script>
import orderGoods from "@/components/order/orderGoods";
// import groupMember from "@/components/order/groupMember";
import HeadNav from "@/components/common/HeadNav";
import orderBtn from "@/components/order/orderBtn";
import {orderCollectDetail} from "@/services/orderApi.js";
import { getPointNum, getStore } from "@/utils/common";
import { integralPart, dealTotal } from "@/utils/goodsUtil";
import {mapState} from 'vuex'
import {orderMixin} from '@/mixins/orderMixin.js'

export default {
  name: "order-collect",
  components: { orderGoods, orderBtn, HeadNav },
  mixins: [orderMixin],
  data() {
    return {
      pay_order_id: this.$route.query.pay_order_id,
      haveDone: 0, // 是否已经倒计时结束
      detail: {},
      status: '',
      total_wx: '',
      total_wallet: '',
      total_integral: '',
      explode_fee_text: [],//运费分类
			payStatus: true,	// 合计展示方式
			source_type: null,
			tripartite: [5, 7, 8, 9, 10, 11, 12],	// 三方支付的type值
			fk_show: true, //false时使用饭卡支付显示
			all_total: null,	// 商品总价
			express_fee: null, // 运费
			intAndMoney: null,   //积分+现金显示
      zgyh_show:true   //中行显隐控制
		};
  },
  methods: {
		// 现金转积分
		integralPart,

    //饭卡商城显隐
    isFk(){
      if (process.env.VUE_APP_FK) {
				let bids = process.env.VUE_APP_FK.split(',');
				bids.forEach( val => {
					if (val == JSON.parse(getStore('businessInfo')).id) {
						this.fk_show = false;
						return;
					}
				});
      }
    },

        // 中行显隐控制
    isZh(){
      if (process.env.VUE_APP_ZHONGHANG) {
				let bids = JSON.parse(process.env.VUE_APP_ZHONGHANG);
				bids.forEach( val => {
					if (val == JSON.parse(getStore('businessInfo')).id) {
						this.zgyh_show = false;
						return;
					}
				});
			}
    },

		// 获取总订单
    async getDetail() {
      try {
        let data = await orderCollectDetail(this.pay_order_id);
        if (data.code === 0) {
          this.detail = data.data;
					this.source_type = this.detail.item_list[0].source_type;
					
					this.infoStatus = this.getVirtualStatus(this.source_type);
					
					// console.log(this.detail.pay_way);
          this.total_wx = Number.parseFloat(data.data.total_wx);
          this.total_wallet = Number.parseFloat(data.data.total_wallet);
          this.total_integral = Number.parseFloat(data.data.total_integral);
					this.all_total = dealTotal(this.detail.item_list);

					this.tripartite.forEach( item => {
						if (item == this.detail.pay_way) {
							this.payStatus = false;
							return;
						}
					});

          let explode_fee_text = []
          let explode_fee = data.data.explode_fee;
          let real_fee_list = data.data.fee_pay_way ? JSON.parse(data.data.fee_pay_way) : '' 
          let keys = Object.keys(explode_fee)
          // let seamKeys = Object.keys(real_fee_list)
          let values = Object.values(explode_fee)
          
					// 积分商城有运费分类时初始化运费
					if (keys.length > 0 && this.businessType == 2) {
						this.express_fee = 0;
					}

          let type = ''
          for (let i = 0; i < keys.length; i++) {
            if (explode_fee[keys[i]] <= 0) {
              break
            }
            if (this.type == 2) {
							if (real_fee_list && real_fee_list[keys[i]].money > 0) {
								Number(values[i]) > 0 ?
								explode_fee_text.push(`${this.getSourceTypes(parseInt(keys[i]))} : ￥${values[i]}`) : 
								''
							} else {
								if (Number(values[i]) > 0) {
									const val = integralPart(1, values[i], 100)
									this.express_fee += Number(val)
									explode_fee_text.push(`${this.getSourceTypes(parseInt(keys[i]))} : ${val}积分`)
								}
							}
            } else {
              Number(values[i]) > 0 ?
							explode_fee_text.push(`${this.getSourceTypes(parseInt(keys[i]))} : ￥${values[i]}`) :
							0
            }
          }
          this.explode_fee_text = explode_fee_text
        }
				
        //status 0未支付，1已支付,2关闭
        // this.status = this.detail.status;
        if (this.detail.status === 0) {
          this.status = 0;
          this.haveDone = 0
        } else if (this.detail.status === 1) {
          this.status = 1
          this.haveDone = 1
        } else if (this.detail.status === 2) {
          this.status = 6
          this.haveDone = 1
        } else if (this.detail.status === 9) {
					this.status = 9
					this.haveDone = 1
				} else if (this.detail.status === 10) {
					this.status = 10
					this.haveDone = 1
				}
      } catch (err) {
        throw err;
      }
    }
  },
  computed: {
    groupNum() {
      let num = 0;
      for (let i = 0; i < this.groupList.length; i++) {
        if (!!this.groupList[i].user_id) {
          num++;
        }
      }
      return num;
    },
    ...mapState({
      type: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      integralRate: state => state.config.integral_rate, // 积分比例
      showWay: state => state.config.show_way, // 商品展示方式，1积分，2现金
      businessType: state => state.businessInfo.type //1现金商城，2积分商品，3内采商城
    }),
  },
  created() {
    this.isFk()
    this.isZh()
    if (this.pay_order_id)
		this.getDetail()
    else this.$router.go(-1)
		document.title = "订单汇总"
  }
};
</script>

<style scoped lang="less">
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.order-collect {
  box-sizing: border-box;
  padding-bottom: 1rem;

  .order-address {
    background: #fff;
    position: relative;
    padding: 0.36rem 0.3rem;
    box-sizing: border-box;
    line-height: 0.4rem;

    .user-info {
      font-size: 0.4rem;
      color: @color-dark-grey;
      margin-bottom: 3px;

      .user-name {
        margin-right: 0.26rem;
      }
    }

    .user-address {
      font-size: 0.22rem;
      color: #666666;
    }

    .order-line {
      height: 3px;
      width: 100%;
      background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
      background-size: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .order-content {
    padding: 0.2rem 0.3rem;
    box-sizing: border-box;
    background: #fbfbfb;

    .goods-shop {
      padding: 0.2rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      color: @color-dark-grey;
      position: relative;

      &::before {
        content: "";
        width: 2px;
        height: 0.2rem;
        background: var(--main-color);
        position: absolute;
        top: 50%;
        margin-top: -0.1rem;
        left: 0;
      }
    }

    .order-info {
      padding: 0 0.2rem;
      box-sizing: border-box;
      background: #fff;
      margin-bottom: 0.3rem;

      .info-title {
        font-size: 0.3rem;
        color: @color-dark-grey;
        padding: 0.2rem 0;
        border-bottom: 1px solid @color-row-line;
        margin-bottom: 0.2rem;
      }

      .info-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        color: @color-dark-grey;
        margin-bottom: 0.2rem;
      }

      .info-all {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        font-size: 0.28rem;
        color: @color-dark-grey;
        height: 1rem;
        border-top: 1px solid @color-row-line;

        p {
          margin-left: 5px;
        }
      }

      .order-line {
        border-bottom: 1px solid @color-row-line;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        color: @color-dark-grey;
      }

      .order-line:nth-last-child(1) {
        border: none;
      }
    }
  }

  .order-btn {
    width: 100%;
    height: 1rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    padding-right: 0.3rem;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  }
}

.fs-weight {
  font-weight: bold;
}
.explode-text {
  text-align: right;
  line-height: 0.4rem;
  font-size: 0.28rem;
}
.color-main {
  color: var(--main-color);
}
</style>
